.SingleSelectOpenedArrow {
    width: 26px;
    height: 26px;
    margin: 4.5px 5px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.settingsli {
    list-style-type: none;
    width: inherit;
  }

.setting {
    min-height:35px;
    display: inline-flex;
    overflow:visible;
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    background-color: #FFFFFF;
    width: 100%;
  }

.settingdisabled {
    display: inline-flex;
    overflow:visible;
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    background-color: #FFFFFF;
    width: 100%;
    pointer-events: none;
    opacity: 0.5;
}

.SettingsExpendedCell {
    line-height: 35px;
    padding-left: 0px;
    color: black;
    font-size: 12px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.searchHighlight {
    text-decoration: underline;
    font-weight: bold;
    background: #FFFF2E;
}

.groupLabel {
    width: 93%;
}
  
.SettingsExpendedCell:hover, .SettingsIconHover:hover {  
    cursor: pointer;
}

.SettingsIconHover {
  height: 12px;
}

.SettingsNotOpenedContainer {
    width: 26px;
    height: 26px;
    border: 1px solid #0033AB;
    border-radius:5px;
    margin: 4.5px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SettingsNotOpenedDisabledContainer {
    width: 26px;
    height: 26px;
    border: 1px solid #0033AB;
    border-radius:5px;
    margin: 4.5px;
    float: left;
    opacity: 0.5;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SettingsOpenedContainer {
  width: 26px;
  height: 26px;
  transform: rotate(180deg);
  margin: 4.5px 5px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SettingsArrowRightIcon {
    height: 23px;
    margin: 2px;
    cursor: pointer;
}

.SelectedCountChip, .TotalCountChip {
    margin: auto 0px;
    height: 20px !important;
    width: 20px;
}

.SelectedCountChip {
    background-color: #0033ab !important;
    color: white !important;
}

.TotalCountChip {
    background-color: white !important;
    color: grey !important;
}

.CountDivider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 7px 0px 5px 5px;
    font-size: medium;
}

.ChipContainer {
    align-items: center;
    display: flex;
}

.SelectedCountChip, .TotalCountChip {
    .MuiChip-label {
        padding: 0px;
    }
}

.SelectDeselectContainer {
    color: blue;
    display: flex;
    flex-direction: row-reverse;
    width: calc(100% - 98px);
    align-items: center;
    line-height: 1;
}

.DeselectAll {
    cursor: pointer;
    margin-left: 4px;
}

.SelectAll {
    cursor: pointer;
    margin-right: 4px;
}

.SettingsExpandedCellLabel {
    display: inline-flex;
    overflow: hidden;
    width: calc(100% - 100px);
}

@media only screen and (max-width: 600px) {
    .SettingsExpandedCellLabel {
        width: 100%;
    }
    .SelectDeselectContainer {
        justify-content: flex-end;
        line-height: 35px;
        width: 100%;
    }
    [class*="SettingsDrawer-paperRoot-"] {
        width: 100vw !important;
    }
    .SettingsExpendedCell {
        display: flex;
        flex-direction: column;
    }
}
html{
  font-size: 12px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #F0F0F0 !important;
}

code {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.applyAllRootContainer {
  display: flex;
  flex-direction: column;
  height: 93%;
  overflow-x: hidden;
  overflow-y: auto;
}

.hide {
  display:none
}

.developerFieldValue {
  display: flex;
  margin-bottom: 16px !important;
  word-break: break-all;
}

.developerField {
  padding: 0px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin: 7px 0px !important;
}

.developerFieldsContainer {
  height: 100vh;
}

.GroupsHeader {
  flex-direction: column;
}

.groupsHeaderContainer {
  display: flex;
}

.PermissionsHeader {
  flex-direction: row;
  display: flex;
}

.PermisssionsContainer {
  height: 89vh;
  overflow: hidden auto;
  padding: 0px 0px 0px 0px;
}

.MuiCardMedia-media {
  object-fit: scale-down !important;
}

@media only screen and (max-height: 675px) {
  .PermisssionsContainer  {
    height: 83vh;
  }
}

@media only screen and (max-width: 590px) {
  .ManageUsersHeader {
      flex-direction: column;
  }

  .PermisssionsContainer  {
    height: 70vh;
  }

  #ManageUsersInviteButton {
      right: 0px;
      position: unset;
      margin: 0px 23px;
  }
  
  .groupsHeaderContainer, .PermissionsHeader{
    display: block;
  }
}
.parentGroupSelect{
  width: 100%;
  margin: 12px 20px 6px 12px;
}
.parentGroupName{
  padding-left: 2px;
}
.label-group {
  color: #333;
  font-size: 12px;
  margin-bottom: 2px;
}
.inputBox {
  border-bottom: 1px solid #333;
  width: calc(100% - 9px);
  height: 35px;
  display: block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin: 0 0px 0 9px;
}

.errorText {
  margin: 3px 0px 0px 9px;
  color: #eb1616 !important;	
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;	
  line-height: 18px;
}

.invalidSelectField {
  border-bottom: 1px solid #EB1616;
  width: calc(100% - 9px);
  height: 35px;
  display: block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin: 0 0px 0 9px;
}

.inputBox .right_icon {
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 20px;
}
.invalidSelectField .right_icon {
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 20px;
  color: #EB1616;
}
.label-Select {
  white-space: nowrap;
  overflow: hidden; 
  display: block;
  width: 80%;
  padding: 10px;
}
.parentDrop {
  list-style: none;
  padding: 0;
  margin: 0;
}
.parentDrop li {
  cursor: pointer;

}
.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.parentDrop div {
  border-bottom: 1px solid #E8E8E8;
 border-left: 1px solid #E8E8E8;
  padding: 8px 12px;

}
.parentDrop div:hover {
  background-color: #FFF;
 border: 1px solid #0033AB;
  padding: 8px 12px;

}
.parentDrop ul {
  list-style: none;
  padding-left: 15px;
  margin: 0;
}
.dropdownMenu .MuiPopover-paper {
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  background-color: #F7F7F7;
}

.parentDrop li > div > .expandIcon {
  margin: -4px 6px;
}
.parentDrop li > div > .expandIcon.up {
  transform: rotate(270deg)
}
.parentDrop li > div > .expandIcon.down {
  transform: rotate(0deg)
}
.parentDrop li.expand > ul {
  display: block;
}
.parentDrop li > ul {
  display: none;
}
.addGroupParent {
  display: flex;
}
.searchBox {
  height: 38px;
  background: #ffffff;
  width: 300px;
}
.searchError {
  color: red;
  padding-top: 7px;
  font-size: 12px;
}


.text-uppercase {
    text-transform: uppercase;
}
.text-center {
    text-align: center;
}
.caret {
    display: inline-block;
    border-top: 5px solid #ffffff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    vertical-align: middle;
    margin-left: 3px;
}

.dropdown {
    margin: 0px 10px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    span {
        color: #ffffff;
        cursor: pointer;
        img {
            margin-right: 5px;
            vertical-align: middle;
        }
 }
         
.dropdown-menu {
        position: absolute;
        top: 50px;
        background-color:#ffffff;            
        width: 200px;
        list-style: none;
        margin: 0;
        padding: 0;
        z-index: 1000000 !important;
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0,0,0,.175);
        border: 1px solid grey;            
        li {
            padding: 8px;               
            cursor: pointer;
            color: #152c73;
            font-size: 13px;
        &:hover {
             background-color: #2041ac;
             color: #ffffff;
        }
        &.active {
            background-color: #690;
            color: #ffffff;
        }
        img {
            margin-right: 3px;
            vertical-align: middle;
        }
      }
    }
}


@media only screen and (max-width: 700px) {
    .dropdown-menu {
        width: 160px !important;
    }
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    min-height: 35px;
    background-color: #d2d2d2;
    padding: 10px 0;
    box-sizing: border-box;
    box-sizing: -webkit-border-box;
    z-index: 999;

    .footer-wrapper {
        display: flex;
        justify-content: space-between;
        span {
            color: #333333;
            font-size: 12px;
        }
        a {
            color: #333333;
            font-size: 12px;
            text-decoration: none;
        }
        .links {
            a {
                margin-right: 40px;
            }
        }
    }
    .details {
        position:relative;
        margin-right:88px;
    }
}